<template>
  <div
    id="avatar_selection"
    class="bg_screen"
  >
    <div class="grid avatar_selection_wrapper">
      <div class="cell cell-9-col">
        <div class="avatar_large_wrapper">
          <transition-group
            name="custom-fade"
            tag="div"
            enter-active-class="animated fadeInLeft"
          >
            <div
              v-for="avatar in avatars"
              v-show="avatar.id === selectedAvatarId"
              :id="`avatar_large${avatar.id}`"
              :key="avatar.id"
              class="avatar_large"
              :class="{
                'active': avatar.id === selectedAvatarId
              }"
            >
              <img
                :src="selectedAvatarImg"
                :alt="selectedAvatarName"
              >
            </div>
          </transition-group>
        </div>
        <div class="avatars_grid">
          <h1>{{ $t("select_avatar") }}</h1>
          <div class="grid">
            <div
              v-for="avatar in avatars"
              :id="`avatar_small${avatar.id}`"
              :key="avatar.id"
              class="avatar_small hexagon grayscale"
              :class="{
                'active': avatar.id === selectedAvatarId
              }"
              @click="selectAvatar(avatar.id, avatar.largeImg, avatar.defaultName)"
            >
              <div class="hexagon-in1">
                <div
                  class="hexagon-in2"
                  :style="{'background-image': 'url(' + avatar.smallImg + ')'}"
                />
              </div>
            </div>
          </div>
          <div class="clr" />
          <transition
            name="custom-fade"
            enter-active-class="animated fadeIn"
          >
            <button
              v-show="selectedAvatarId > 0"
              id="avatar_submit"
              ref="submitAvatar"
              class="img-btn img-hover-btn"
              @click="submitAvatar"
            />
          </transition>
        </div>
      </div>
      <div class="cell cell-3-col avatar_descr_wrapper">
        <transition-group
          name="custom-fade"
          tag="div"
          enter-active-class="animated fadeInRight"
        >
          <div
            v-for="avatar in avatars"
            v-show="avatar.id === selectedAvatarId"
            :id="`avatar_descr${avatar.id}`"
            :key="avatar.id"
            class="avatar_descr"
            :class="{
              'active': avatar.id === selectedAvatarId
            }"
          >
            <h2 class="avatar_default_name">
              {{ avatar.defaultName }}
            </h2>
            <h3 class="avatar_type">
              {{ avatar.avatarType }}
            </h3>
            <div class="avatar_details">
              <div v-html="avatar.description" />
            </div>
          </div>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AvatarSelection',
  data() {
    return {
      selectedAvatarId: null,
      selectedAvatarName: '',
      selectedAvatarImg: ''
    }
  },
  computed: {
    gameplay() {
      return this.$store.getters.gameplay
    },

    avatars() {
      return this.gameplay.avatarSelection.avatarList
    },

    avatarsDB() {
      return TAFFY(this.gameplay.avatarSelection.avatarList)
    }
  },
  mounted() {
    this.bgSound.play()
  },
  methods: {
    selectAvatar(avatarId, largeImg, title) {
      this.selectedAvatarId = avatarId
      this.selectedAvatarName = title
      this.selectedAvatarImg = largeImg
    },

    submitAvatar() {
      if (!this.selectedAvatarId) return

      this.continueSound.play()
      this.startBtnLoading(this.$refs.submitAvatar)

      this.$store.dispatch('setAvatar', {
        id: this.selectedAvatarId,
        name: this.selectedAvatarName
      })
        .then(res => {
          const gameAvatar = this.avatarsDB({ id: this.selectedAvatarId }).first()
          this.$store.commit('setGameAvatar', gameAvatar)

          this.endBtnLoading(this.$refs.submitAvatar)

          this.sendGameProgressToGA('avatar_selected', this.selectedAvatarName, this.selectedAvatarId)

          this.$parent.routeGame()
        })
    }
  }
}
</script>
