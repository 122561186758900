var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bg_screen", attrs: { id: "avatar_selection" } },
    [
      _c("div", { staticClass: "grid avatar_selection_wrapper" }, [
        _c("div", { staticClass: "cell cell-9-col" }, [
          _c(
            "div",
            { staticClass: "avatar_large_wrapper" },
            [
              _c(
                "transition-group",
                {
                  attrs: {
                    name: "custom-fade",
                    tag: "div",
                    "enter-active-class": "animated fadeInLeft",
                  },
                },
                _vm._l(_vm.avatars, function (avatar) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: avatar.id === _vm.selectedAvatarId,
                          expression: "avatar.id === selectedAvatarId",
                        },
                      ],
                      key: avatar.id,
                      staticClass: "avatar_large",
                      class: {
                        active: avatar.id === _vm.selectedAvatarId,
                      },
                      attrs: { id: "avatar_large" + avatar.id },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.selectedAvatarImg,
                          alt: _vm.selectedAvatarName,
                        },
                      }),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "avatars_grid" },
            [
              _c("h1", [_vm._v(_vm._s(_vm.$t("select_avatar")))]),
              _c(
                "div",
                { staticClass: "grid" },
                _vm._l(_vm.avatars, function (avatar) {
                  return _c(
                    "div",
                    {
                      key: avatar.id,
                      staticClass: "avatar_small hexagon grayscale",
                      class: {
                        active: avatar.id === _vm.selectedAvatarId,
                      },
                      attrs: { id: "avatar_small" + avatar.id },
                      on: {
                        click: function ($event) {
                          return _vm.selectAvatar(
                            avatar.id,
                            avatar.largeImg,
                            avatar.defaultName
                          )
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "hexagon-in1" }, [
                        _c("div", {
                          staticClass: "hexagon-in2",
                          style: {
                            "background-image": "url(" + avatar.smallImg + ")",
                          },
                        }),
                      ]),
                    ]
                  )
                }),
                0
              ),
              _c("div", { staticClass: "clr" }),
              _c(
                "transition",
                {
                  attrs: {
                    name: "custom-fade",
                    "enter-active-class": "animated fadeIn",
                  },
                },
                [
                  _c("button", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.selectedAvatarId > 0,
                        expression: "selectedAvatarId > 0",
                      },
                    ],
                    ref: "submitAvatar",
                    staticClass: "img-btn img-hover-btn",
                    attrs: { id: "avatar_submit" },
                    on: { click: _vm.submitAvatar },
                  }),
                ]
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "cell cell-3-col avatar_descr_wrapper" },
          [
            _c(
              "transition-group",
              {
                attrs: {
                  name: "custom-fade",
                  tag: "div",
                  "enter-active-class": "animated fadeInRight",
                },
              },
              _vm._l(_vm.avatars, function (avatar) {
                return _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: avatar.id === _vm.selectedAvatarId,
                        expression: "avatar.id === selectedAvatarId",
                      },
                    ],
                    key: avatar.id,
                    staticClass: "avatar_descr",
                    class: {
                      active: avatar.id === _vm.selectedAvatarId,
                    },
                    attrs: { id: "avatar_descr" + avatar.id },
                  },
                  [
                    _c("h2", { staticClass: "avatar_default_name" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(avatar.defaultName) +
                          "\n          "
                      ),
                    ]),
                    _c("h3", { staticClass: "avatar_type" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(avatar.avatarType) +
                          "\n          "
                      ),
                    ]),
                    _c("div", { staticClass: "avatar_details" }, [
                      _c("div", {
                        domProps: { innerHTML: _vm._s(avatar.description) },
                      }),
                    ]),
                  ]
                )
              }),
              0
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }